import React from 'react';

export const G1Links = ({ link, label }) => {
  return (
    <a
      onClick={() => {
        window.location.href = link;
      }}
      rel='nofollow noreferrer'
    >
      {label}
    </a>
  );
};
