import React, { useEffect } from 'react';
import { YTFrames } from '../components/YTFrames';
import { RainbowButton } from '../components/buttons';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: '1em',
    textAlign: 'center',
    maxWidth: '500px',
  },
  rainbowBtn: {
    marginRight: '0.5em',
  },
  items: {},
});

export const G1Youtube = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'YouTube Channels';
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.rainbowBtn}>
        <RainbowButton label='Subscribe' onClick={() => {}} />
      </div>
      <div className={classes.items}>
        {arr.map(({ link }, index) => (
          <>
            <br />
            <YTFrames embedId={link} />
            <br />
          </>
        ))}
      </div>
    </div>
  );
};

const arr = [
  {
    link: 'https://www.youtube.com/embed/1mZPI2AsF6k?si=CL2ImLLPHqdIf4CT',
  },
  {
    link: 'https://www.youtube.com/embed/8p6lVSDguDE?si=4_aep7eIK5A5xy3n',
  },
  {
    link: 'https://www.youtube.com/embed/NKCSYlmVdlY?si=KMMOTUjF0kDCbLdn',
  },
  {
    link: 'https://www.youtube.com/embed/IPbFYIZxs60?si=H8Cr43yLyrMtU9RW',
  },
  {
    link: 'https://www.youtube.com/embed/BowqD8gbWfc?si=X2sVrdqmuIX2qhfm',
  },
  {
    link: 'https://www.youtube.com/embed/1o3Vi3JE1qc?si=g-bG-ifehtISnRSz',
  },
];
