import React, { useState } from 'react';

import { Button, makeStyles } from '@fluentui/react-components';
import {
  NavigationFilled,
  WeatherSunnyRegular,
  WeatherMoonFilled,
} from '@fluentui/react-icons';
import { G1Drawer } from '../components/drawer';
import { useNavigate } from 'react-router-dom';
import { ShiningText } from './shining-text/ShiningText';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid gray',
    borderBottom: '1px solid gray',
    paddingInline: '1em',
    marginBottom: '1em',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    lineHeight: '2em',
    fontFamily: 'cursive',
    color: 'rgb(36, 161, 222)',
    borderLeft: '1px solid #808080',
  },
  sideLinks: {
    '& ul': {
      listStyle: 'none',
      paddingLeft: 0,
    },
    '& li': {
      lineHeight: '2.5em',
      fontWeight: 500,
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
});

export const G1Header = ({ isDark, changeTheme }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const onTitleClick = () => {
    navigate('/');
  };

  return (
    <div className={classes.root}>
      <div
        style={{ cursor: 'pointer', fontSize: '2em', marginRight: '0.25em' }}
      >
        {isDark ? (
          <WeatherSunnyRegular onClick={changeTheme} />
        ) : (
          <WeatherMoonFilled onClick={changeTheme} />
        )}
      </div>
      <div className={classes.header} onClick={onTitleClick}>
        <img loading="lazy"
          src='/assets/tabTitleIcon.webp'
          alt='GC'
          style={{
            fontSize: '2em',
            height: '1.25em',
            marginRight: '0.5em',
            marginBottom: '-0.125em',
          }}
        />
        <h1>Group Chats</h1>
      </div>
      <Button
        appearance='subtle'
        aria-label='Open-Navigation-Menu'
        icon={<NavigationFilled />}
        onClick={toggleDrawer}
      />
      <G1Drawer
        isOpen={isOpen}
        toggle={toggleDrawer}
        content={<G1SideBar sideLinks={sideLinks} />}
      />
    </div>
  );
};

const shiningTextProps = {
  style: { size: '1em', textDecoration: 'underline' },
};

const sideLinks = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'Find Telegram groups',
    link: '/telegram/view',
  },
  {
    label: 'Find Whatsapp groups',
    link: '/whatsapp/view',
  },
  {
    label: 'User Guide',
    link: '/user-guide',
  },
  {
    label: <hr style={{ color: 'blue' }} />,
    link: '#',
  },
  {
    label: 'Earn Money (WHATSAPP) ',
    link: '/blogs/earn-money/whatsapp',
  },
  {
    label: 'Earn Money (TELEGRAM) ',
    link: '/blogs/earn-money/telegram',
  },
  {
    label: 'Dating (TELEGRAM) ',
    link: '/blogs/dating/telegram',
  },
  {
    label: 'Jobs (TELEGRAM) ',
    link: '/blogs/jobs/telegram',
  },
  {
    label: (
      <ShiningText text='Trending on Social Media' {...shiningTextProps} />
    ),
    link: '/trending',
  },
];

const G1SideBar = ({ sideLinks }) => {
  const classes = useStyles();
  return (
    <div className={classes.sideLinks}>
      <ul>
        {sideLinks.map(({ label, link }) => (
          <li key={label}>
            <a href={link}>{label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
