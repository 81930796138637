import React from 'react';

export const YTFrames = ({ embedId }) => {
  return (
    <div
      style={{
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: 0,
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={embedId}
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        // referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen
      />
    </div>
  );
};
