import React, { useEffect } from 'react';
import { makeStyles } from '@fluentui/react-components';
import { ShiningText } from '../shining-text/ShiningText';
import { G1Links } from './utils';

const useStyles = makeStyles({
  root: {
    maxWidth: '500px',
    marginInline: 'auto',
    textAlign: 'center',
    margin: '1em',
    '&>img': {
      width: '95%',
    },
    '&>span, li': {
      textAlign: 'left',
      fontWeight: 500,
    },
    '& a': {
      color: 'rgb(0, 0, 238)',
      textDecorationLine: 'underline',
    },
  },
});

export const EarnMoneyTelegram = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Earn Money (TELEGRAM)';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img loading='lazy' src='/assets/earn_5.webp' alt='Money cash' />
        <span>
          Telegram groups and channels have a huge capacity when it comes to the
          total number of members. There are ways to establish either one-way or
          two-way communication. In one-way communication, only admins can send
          messages. In two-way communication, all members can chat (or call)
          with each other. Use these methods to generate extra income every day.
        </span>
        <ul>
          <li>Run paid PROMOTIONS</li>
          <li>You can work as a FREELANCER</li>
          <li>Become AFFILIATE marketer</li>
          <li>Subscribtion groups</li>
          <li>You can earn money ONLINE</li>
          <li>PROMO CODE and DISCOUNTS in group chats</li>
          <li>Start TRAINING other people for BUSINESS</li>
          <li>Some earning for STUDENTS</li>
          <li>Make new FRIENDS in the group</li>
          <li>BUY and SELL your products</li>
          <li>Promote your YOUTUBE channel</li>
        </ul>
        <img loading='lazy' src='/assets/earn_6.webp' alt='Money cash' />
        <span className={classes.text}>
          These days, earning money from social media and online groups has
          become very easy. You can earn a good amount every day through various
          methods. You can join the groups below and start earning.
        </span>
        <ul>
          {[...set_1, ...crypto].map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
        <img loading='lazy' src='/assets/earn_4.webp' alt='Money cash' />
        <span>
          Earning money online means working on your smartphone or laptop from
          home and getting paid for it. Many people are interested in this type
          of work because, in today's digital world, almost everyone has a
          smartphone or laptop and uses them to complete tasks.
        </span>
        <ul>
          {marketing.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const set_1 = [
  {
    label: 'Oro Airdrops Chat',
    link: 'https://t.me/oro_airdrops_group',
  },
  {
    label: 'Airdrop Brasil Community',
    link: 'https://t.me/airdropsbrasilcommunity',
  },
  {
    label: 'GrowDealz',
    link: 'https://t.me/+44xLEWqS4jBlMDc1',
  },
  {
    label: 'frcp_official',
    link: 'https://t.me/frcp_official',
  },
  {
    label: 'Deal Way',
    link: 'https://telegram.me/+4mDsICGhfoU3MWI1',
  },
  {
    label: 'Loot_offers_deals',
    link: 'https://t.me/Loot_Offers_Dealss',
  },
  {
    label: 'AP Airdrops',
    link: 'https://t.me/dvagroupsafty',
  },
  {
    label: 'CoolzTricks official',
    link: 'https://t.me/icoolzTricks',
  },
  {
    label: 'TeluguTechTVDeals',
    link: 'https://t.me/telugutechtvdeals',
  },
  {
    label: 'Trading Airdrops Bots',
    link: 'https://t.me/apocaltraderfree',
  },
  {
    label: 'Tech Offers Today',
    link: 'https://telegram.me/s/Techofferstoday',
  },
  {
    label: 'Best deals India',
    link: 'https://t.me/BestDealsInIND',
  },
  {
    label: 'DealsPoint',
    link: 'https://t.me/dealspoint',
  },
  {
    label: 'Tech Deals Official',
    link: 'https://t.me/takedealsofficialloots',
  },
  {
    label: 'TechOnline',
    link: 'https://t.me/tekonline',
  },
  {
    label: 'TrickXpert',
    link: 'https://t.me/TrickXpert',
  },
  {
    label: 'BIG DEALS',
    link: ';https://t.me/+njR_VQui31Q0YTll',
  },
  {
    label: 'Tech 24 Deals',
    link: 'https://t.me/tech24deals',
  },
  {
    label: 'OfferZones Deals',
    link: 'https://t.me/Offerzone_deals',
  },
  {
    label: 'I Coolz Tricks',
    link: 'https://t.me/icoolzTricks',
  },
  {
    label: 'Extra Pe',
    link: 'https://t.me/+W67nLogfEgU5NjE1',
  },
  {
    label: 'Tech India Firm',
    link: 'https://t.me/techindiafirmofficial',
  },
];

const crypto = [
  {
    label: 'Airdrop alert',
    link: 'https://telegram.me/AirdropAlertDailyCHAT',
  },
  {
    label: 'Bitbns community',
    link: 'https://telegram.me/Bitbns',
  },
  {
    label: 'Crypto.com',
    link: 'https://telegram.me/CryptoComOfficial',
  },
  {
    label: 'Binance english',
    link: 'https://telegram.me/binanceexchange',
  },
  {
    label: 'KuCoin exchange',
    link: 'https://telegram.me/Kucoin_Exchange',
  },
];

const marketing = [
  {
    label: 'Wealthy Affiliate',
    link: 'https://t.me/wealthy_affiliate',
  },
  {
    label: 'Affiliate Marketing English',
    link: 'https://t.me/affiliate_marketing_english',
  },
  {
    label: 'Dx30 Likes | 🚀BOOSTIFY🚀',
    link: 'https://telegram.me/Dx30BOOSTIFY',
  },
  {
    label: 'Digital Marketing Jobs',
    link: 'https://telegram.me/Digitalmarketingjobs',
  },
  {
    label: 'eDeal Affiliate Network',
    link: 'https://t.me/eDealAffiliateNetwork',
  },
  {
    label: 'Growth | C&L | DX10',
    link: 'https://telegram.me/growthengagement',
  },
  {
    label: 'Affiliate marketing World',
    link: 'https://t.me/AffiliateMarketing_Cpa',
  },
  { label: 'Affiliate Bank', link: 'https://t.me/Affiliatebank' },
  {
    label: 'Marketing Motivation',
    link: 'https://telegram.me/marketingmotivationn',
  },
  {
    label: 'Affiliate Marketing',
    link: 'https://t.me/affiliatemarketers',
  },
  {
    label: 'Industry',
    link: 'https://t.me/AdultMastermind',
  },
  {
    label: 'Affiliate Marketing HUB',
    link: 'https://t.me/theaffhub',
  },
  {
    label: 'Digital Marketing 💡 💻',
    link: 'https://telegram.me/digital4marketing',
  },
  {
    label: 'Performance Marketing MM',
    link: 'https://t.me/PerformanceMarketingMM',
  },
  {
    label: 'The Affiliate Marketers',
    link: 'https://t.me/affiliateconfEng',
  },
  {
    label: 'Affiliate Marketing Tricks',
    link: 'https://t.me/affiliatemarketingTricks',
  },
  {
    label: 'Real Affiliate mnarketing',
    link: 'https://t.me/affiliatesmarketing',
  },
  {
    label: 'I AM Affiliate',
    link: 'https://t.me/iamaffiliate',
  },
];

const tech = [
  {
    label: 'Top tech programs',
    link: 'https://telegram.me/TopTechPrograms',
  },
  {
    label: 'Telegram Android Talks',
    link: 'https://telegram.me/TelegramAndroidTalk',
  },
  {
    label: 'Geeks Chat',
    link: 'https://telegram.me/geeksChat',
  },
  {
    label: 'Tech talks',
    link: 'https://telegram.me/globaltechtalks',
  },
  {
    label: 'Telegram bot talk',
    link: 'https://telegram.me/BotTalk',
  },
];

const art = [
  {
    label: 'Chatroom GIFs & Stickers',
    link: 'https://telegram.me/gifchat',
  },
  {
    label: 'Pocophone F1 | PHOTOGRAPHY',
    link: 'https://telegram.me/PocoPhonePhotography',
  },
  {
    label: 'XiaoMeme | Photography',
    link: 'https://telegram.me/XiaoMemePhotography',
  },
  {
    label: 'RN4 Photography',
    link: 'https://telegram.me/rn4photography',
  },
  {
    label: 'Mobile Photography | OFFICIAL',
    link: 'https://telegram.me/ZenfonePhotography',
  },
];

const software = [
  {
    label: 'C/C++ Programming',
    link: 'https://telegram.me/programminginc',
  },
  {
    label: 'Dart / Flutter',
    link: 'https://telegram.me/dartlang_group',
  },
  {
    label: 'PHP Developers',
    link: 'https://telegram.me/codeslide',
  },
  {
    label: 'Python Edureka',
    link: 'https://telegram.me/pythonedureka',
  },
  {
    label: 'Data Science,ML & AI Nugget Chats',
    link: 'https://telegram.me/DatascienceChats',
  },
  {
    label: 'AIM4PG',
    link: 'https://telegram.me/aim4pg',
  },
  {
    label: 'Azure, AWS, .net, Python, ML & DataScience',
    link: 'https://telegram.me/python_programmers_club',
  },
  {
    label: 'Angular Developers',
    link: 'https://telegram.me/angularDevelopers',
  },
  {
    label: 'Designers Hub – UI/UX',
    link: 'https://telegram.me/how2design',
  },
  {
    label: 'Java Programming Chat',
    link: 'https://telegram.me/javaprogrammingchat',
  },
];

const business = [
  {
    label: 'Business & Finance chat',
    link: 'https://telegram.me/finance_chat',
  },
  {
    label: 'Entrepreneurial journey (EJ)',
    link: 'https://telegram.me/entrepreneurialjourney',
  },
  {
    label: 'Business Analysis Knowledge Forum',
    link: 'https://telegram.me/Be_A_BA',
  },
  {
    label: 'Import & Export Europe✈️',
    link: 'https://telegram.me/Import_Export_Europe',
  },
  {
    label: 'Entrepreneurs Group',
    link: 'https://telegram.me/entrepreneursupport',
  },
];

const nutrition = [
  {
    label: 'Food Science and Nutrition',
    link: 'https://telegram.me/foodornutrition',
  },
  {
    label: 'Natural Health & Wellbeing',
    link: 'https://telegram.me/Fitness_Health_wellbeing_chat',
  },
  {
    label: 'Food Hunters 🍛😋 | Kerala	',
    link: 'https://telegram.me/FoodHunters',
  },
  {
    label: 'Yoga & Meditation	',
    link: 'https://telegram.me/yoga_meditation_JS',
  },
  {
    label: 'Yoga learning🧘‍♂🧘‍♀',
    link: 'https://telegram.me/yogalearners',
  },
];
