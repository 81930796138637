import React from 'react';
import { Button, makeStyles } from '@fluentui/react-components';
import {
  ChevronDoubleLeftFilled,
  ChevronDoubleRightFilled,
} from '@fluentui/react-icons';

const useStyles = makeStyles({
  root: {
    marginTop: '0.5em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      minWidth: '0.75em !important',
      border: '1px solid gray',
      margin: '0.125em',
      boxShadow: '0px 0px 5px  rgba(0,0,0,0.5)',
    },
  },
});

export const G1Pagination = ({
  activePage = 1,
  itemsCount = 1,
  updatePageNumber = () => {},
}) => {
  const classes = useStyles();
  const totalPages = Math.ceil(itemsCount / 10);

  const onLeft = () => {
    if (activePage <= 1) {
      return;
    }
    updatePageNumber((val) => val - 1);
  };
  const onRight = () => {
    if (activePage >= totalPages) {
      return;
    }
    updatePageNumber((val) => val + 1);
  };

  return (
    <div className={classes.root}>
      <Button
        appearance='subtle'
        icon={<ChevronDoubleLeftFilled />}
        onClick={onLeft}
        disabled={activePage <= 1}
      />
      <Button appearance='subtle'>{activePage}</Button>
      <Button
        appearance='subtle'
        icon={<ChevronDoubleRightFilled />}
        onClick={onRight}
        disabled={activePage >= totalPages}
      />
    </div>
  );
};
