import React from 'react';
import { Button } from '@fluentui/react-components';

export const RainbowButton = ({ label, onClick }) => {
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        backgroundImage:
          'linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%)',
        padding: '0.25em',
      }}
    >
      <Button
        onClick={onClick}
        style={{
          lineHeight: '1.5em',
          backgroundColor: 'black',
          color: 'white',
          fontSize: '1.5em',
          width: '100%',
        }}
      >
        <b>{label}</b>
      </Button>
    </div>
  );
};
