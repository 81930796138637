import React, { useEffect } from 'react';
import { makeStyles } from '@fluentui/react-components';
import { ShiningText } from '../shining-text/ShiningText';
import { G1Links } from './utils';

const useStyles = makeStyles({
  root: {
    maxWidth: '500px',
    marginInline: 'auto',
    textAlign: 'center',
    margin: '1em',
    '&>img': {
      width: '95%',
    },
    '&>span, li': {
      textAlign: 'left',
      fontWeight: 500,
    },
    '& a': {
      color: 'rgb(0, 0, 238)',
      textDecorationLine: 'underline',
    },
  },
});

export const EarnMoneyWhatsapp = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Earn Money (WHATSAPP)';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='WHATSAPP POST' />
        <img loading='lazy' src='/assets/earn_3.webp' alt='Money cash' />
        <span>Use these ways to generate extra income every day.</span>
        <ul style={{ listStyleType: 'square' }}>
          <li>You can work as a FREELANCER</li>
          <li>Become AFFILIATE marketer</li>
          <li>You can earn money ONLINE</li>
          <li>PROMO CODE and DISCOUNTS in group chats</li>
          <li>Start TRAINING other people for BUSINESS</li>
          <li>Some earning for STUDENTS</li>
          <li>Make new FRIENDS in the group</li>
          <li>BUY and SELL your products</li>
          <li>Promote your YOUTUBE channel</li>
        </ul>
        <img loading='lazy' src='/assets/earn_1.webp' alt='Money cash' />
        <span className={classes.text}>
          These days, earning money from social media and online groups has
          become very easy. You can earn a good amount every day through various
          methods. You can join the groups below and start earning.
        </span>
        <ul>
          {set_1.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
        <img loading='lazy' src='/assets/earn_2.webp' alt='Money cash' />
        <span>
          Earning money online means working on your smartphone or laptop from
          home and getting paid for it. Many people are interested in this type
          of work because, in today's digital world, almost everyone has a
          smartphone or laptop and uses them to complete tasks.
        </span>
        <ul>
          {set_2.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const set_1 = [
  {
    label: 'Online earning',
    link: 'https://chat.whatsapp.com/KXqOoj90xcoEnm8UfSeca5',
  },
  {
    label: 'Online game earning',
    link: 'https://chat.whatsapp.com/HFXZj76EvRPAUwgonDbCLl',
  },
  {
    label: 'Daily Earnings',
    link: 'https://chat.whatsapp.com/LNJRBsksi2SF0CnX0ob61U',
  },
  {
    label: 'Free Digital Products',
    link: 'https://chat.whatsapp.com/GTktlnaOLeBLM2pRE5AHkL',
  },
];

const set_2 = [
  {
    label: 'Money Earning',
    link: 'https://chat.whatsapp.com/JfQLvZuAV1AGlK7fBOi84h',
  },
  {
    label: 'Earn by GAMING',
    link: 'https://chat.whatsapp.com/Jknxzyj9Ml6CKvmDJJZioc',
  },
  {
    label: 'True care official 67',
    link: 'https://chat.whatsapp.com/L0j6zYnmRn516QCFuzG9Dx',
  },
  {
    label: 'Guest Post',
    link: 'https://chat.whatsapp.com/CgGPDNSpKE75d7XLuqLbsm',
  },
  {
    label: 'Online shopping free earning',
    link: 'https://chat.whatsapp.com/E1xbFRhiill3op2RMrosV0',
  },
  {
    label: 'Amadox brand',
    link: 'https://chat.whatsapp.com/CKt77iC9Rly14sn4Fknknn',
  },
  {
    label: 'MATIC fortune',
    link: 'https://chat.whatsapp.com/G2qJWIOJAkb1ruNktFYiXx',
  },
  {
    label: 'Betting TIPS - Australia, Europe and Usa gamblers only',
    link: 'https://chat.whatsapp.com/D68uqsQP6ftBq04moqgDIN',
  },
  {
    label: 'Capital gain Pro',
    link: 'https://chat.whatsapp.com/EilRVSDeeqWIbm2GxQ7kav',
  },
  {
    label: 'Online Work Available',
    link: 'https://chat.whatsapp.com/IwGtbV2TggMDNENp9ojSx6',
  },
  {
    label: 'Automatic Earning',
    link: 'https://chat.whatsapp.com/INk8S4uW6YC700LpiJIxJG',
  },
  {
    label: 'Asli Paisa Kamao',
    link: 'https://chat.whatsapp.com/K0poHgxiVvS00Eher9rrdu',
  },
  {
    label: 'Earn from MOBILE phone',
    link: 'https://chat.whatsapp.com/GXVN0dBqZBDI1JlYBVyPoF',
  },
  {
    label: 'Update Permission',
    link: 'https://chat.whatsapp.com/GpqZ0xSSUcG30rcqNEBfrV',
  },
  {
    label: 'CASH UNIVERSITY',
    link: 'https://chat.whatsapp.com/CiIqx5N3NZyIXJ27FhYFXR',
  },
  {
    label: 'Treasure NFT online',
    link: 'https://chat.whatsapp.com/EqQhD2EXfm3DQXCrWsdEjj',
  },
  {
    label: 'Whatsapp File Work',
    link: 'https://chat.whatsapp.com/KIcPlzs4aeAC96aql64rXS',
  },
  {
    label: 'Online Earning Work',
    link: 'https://chat.whatsapp.com/CkUbB5NceubDHyDmkAa8SS',
  },
  {
    label: 'Make Money Online',
    link: 'https://chat.whatsapp.com/E5Or0K2LcwNJKs7rkidiUh',
  },
  {
    label: 'Refer Earning Money',
    link: 'https://chat.whatsapp.com/CSInqkyyeDu88ETQE0joLe',
  },
  {
    label: 'LEGIT UPDATES',
    link: 'https://chat.whatsapp.com/I7VZCDeCA5ZC8pTZ85pHZI',
  },
  {
    label: 'Mining Income Daily',
    link: 'https://chat.whatsapp.com/DxURuKJinUbGPYhXTimGmO',
  },
  {
    label: 'World Best Online Earning',
    link: 'https://chat.whatsapp.com/In5FrSAl9oB3EL3iXMXhoS',
  },
  {
    label: 'Daily Earning GOLD NFT',
    link: 'https://chat.whatsapp.com/BnhFGbTqvB8BvzOaaWrjSV',
  },
  {
    label: 'Work and Investment',
    link: 'https://chat.whatsapp.com/Gg17W33XWGFHEttzaU26NL',
  },
  {
    label: 'Daily Earning zero Investment',
    link: 'https://chat.whatsapp.com/CEfHJ6ylDp66pp3hBlfypa',
  },
  {
    label: 'Money Earning Group',
    link: 'https://chat.whatsapp.com/LDfd4XTQROSFpJxJYjbEV7',
  },
  {
    label: 'Next Real earning Platform',
    link: 'https://chat.whatsapp.com/JA8eaa8sp95C91QGlczaTP',
  },
  {
    label: 'High Stakes Heaven',
    link: 'https://chat.whatsapp.com/HM0CuACyREm4nLMmRvfK1H',
  },
  {
    label: 'Navi Work From Home',
    link: 'https://chat.whatsapp.com/BakiOoNlCjQLX0tv3WS7Ay',
  },
  {
    label: 'Online Earning money',
    link: 'https://chat.whatsapp.com/GM1C4BBjyAt4VBHpv5KdIH',
  },
  {
    label: 'Free Income factory',
    link: 'https://chat.whatsapp.com/L95iVUCRMAi7knksIGy2AM',
  },
  {
    label: 'Real Earning',
    link: 'https://chat.whatsapp.com/LXj5dWj99Hd477nAng7UGD',
  },
  {
    label: 'No Investment Work',
    link: 'https://chat.whatsapp.com/E3J7u7t4e0m9otdr89aj2Q',
  },
  {
    label: 'Earning Money in Pakistan',
    link: 'https://chat.whatsapp.com/D5cKC0nrbQxBr98QulLxVS',
  },
  {
    label: 'Zero Investment Monthly Income',
    link: 'https://chat.whatsapp.com/Dg68LJzIBFW7j9uMXR7Mix',
  },
];
