import React from 'react';
import { useStyles } from './styles';
import {
  Card,
  CardHeader,
  CardPreview,
  Body1,
  Caption1,
} from '@fluentui/react-components';
import { YTFrames } from '../../components/YTFrames';
import { G1Youtube } from '../Youtube';

export const G1Trending = ({ embedId }) => {
  return <G1Youtube />;
};
