import React from 'react';

import { Body1, Caption1, Button } from '@fluentui/react-components';
import { ArrowReplyRegular, AddCircleFilled } from '@fluentui/react-icons';
import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
} from '@fluentui/react-components';
import { useStyles } from './styles';
import { RainbowButton } from '../buttons';

export const G1Cards = ({
  header,
  preview,
  totalGroups,
  onViewGroup,
  onAddGroup,
  onCardClick,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} key={header.text}>
      <CardHeader
        image={
          <img loading="lazy" src={header.logo} alt='Group Logo' className={classes.logo} />
        }
        header={
          <Body1>
            <b>{header.text}</b>
          </Body1>
        }
        description={
          totalGroups ? <Caption1>{totalGroups} - Groups</Caption1> : null
        }
      />

      <CardPreview
        logo={null}
        onClick={onViewGroup || onCardClick}
        style={{ cursor: 'pointer' }}
      >
        <img loading="lazy" src={preview.image} alt={preview.altText} />
      </CardPreview>

      <CardFooter className={classes.footer}>
        {onViewGroup && (
          <Button
            onClick={onViewGroup}
            icon={<ArrowReplyRegular />}
            className={classes.footerButton}
          >
            See Active Groups
          </Button>
        )}
        {onAddGroup && (
          <Button
            onClick={onAddGroup}
            icon={<AddCircleFilled />}
            className={classes.footerButton}
          >
            Add New Group
          </Button>
        )}
        {onCardClick && <RainbowButton label='Open' onClick={onCardClick} />}
      </CardFooter>
    </Card>
  );
};
