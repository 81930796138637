import React, { useEffect } from 'react';
import { G1Cards } from '../components/cards';
import { useFetch } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1em',

    '@media screen and (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
});

const landingPageCards = ({
  total_telegram_entries,
  total_whatsapp_entries,
  navigate = () => {},
}) => [
  {
    header: {
      logo: '/assets/logo_telegram.webp',
      text: 'Telegram Groups',
    },
    preview: {
      image: '/assets/preview_telegram.webp',
      altText: 'Telegram Group Collection PNG',
    },
    totalGroups: total_telegram_entries,
    onViewGroup: () => navigate('/telegram/view'),
    onAddGroup: () => navigate('/telegram/add'),
  },
  {
    header: {
      logo: 'assets/logo_whatsapp.webp',
      text: 'WhatsApp Groups',
    },
    preview: {
      image: '/assets/preview_whatsapp.webp',
      altText: 'whatsapp Group Collection PNG',
    },
    totalGroups: total_whatsapp_entries,
    onViewGroup: () => navigate('/whatsapp/view'),
    onAddGroup: () => navigate('/whatsapp/add'),
  },
  {
    header: {
      logo: '/assets/trending.webp',
      text: 'YouTube Channels',
    },
    preview: {
      image: '/assets/trending.webp',
      altText: 'YouTube Channel Collection PNG',
    },
    onCardClick: () => navigate('/youtube/view'),
  },
];

export const G1MainPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Group Chat Links | Whatsapp | Telegram | Online Chat';
  }, []);

  const resp = useFetch({ endpoint: 'count/index.php?val=1' });
  const { total_telegram_entries, total_whatsapp_entries } = resp.data || {};
  const items = landingPageCards({
    total_telegram_entries,
    total_whatsapp_entries,
    navigate,
  });

  return (
    <div className={classes.root}>
      {items.map((item) => (
        <G1Cards {...item} />
      ))}
    </div>
  );
};
